import React from 'react';
import './Home.css';
import Image from './AaronFrontImage.png';

function Home() {
  return (
    <>
      <section id="home" className="home-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="image-container position-relative top-0 start-50 translate-middle-x">
                <img src={Image} alt="Aaron Gholson" className="img-fluid centered-image" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="title-container position-relative top-0 start-50 translate-middle-x">
                <div className="title-block">
                  <span className="white-line">|</span> <h1 className="home-title">Content Creator</h1>
                </div>
                <div className="title-block">
                  <span className="white-line">|</span> <h1 className="home-title">Software Engineer</h1>
                </div>
                <div className="title-block">
                  <span className="white-line">|</span> <h1 className="home-title">Indie Game Developer</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
