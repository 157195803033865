import React from 'react';
import './About.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from './AaronSideProfile.png';

function About() {
  return (
    <div className="container">
      <h1 className="about-title">
        <span className="Hello">Hello!</span> <span>I'm Aaron Gholson</span>
      </h1>
      <div className="row" id='top-row'>
        <div className="col-md-8">
          <p className="about-text">When I was six years old, I completed my favorite video game 100%. The reward was a behind-the-scenes video showing the development process and the people involved. This short video ignited my interest in game development, and from that moment, I knew I wanted to pursue it as a career. </p>
          <p className="about-text">At 11, I got my first computer. Although it was so old it couldn’t even run Minecraft, it allowed me to explore the internet and learn as much as I could about game development and coding. Two years later, at 13, I got a laptop and began my adventures in Unity 3D. I watched countless hours of YouTube tutorials, meticulously following each one, learning as much as possible. Eventually, I became proficient in C# and started experimenting with creating my own games. During this time, I also developed my skills outside of coding with programs like Blender and Photoshop. I had many small successes, creating little games to show my friends, but I never released anything officially.</p>
        </div>
        <div className="col-6 col-md-4 d-flex justify-content-center align-items-center">
          <img src={Image} alt="Headshot of Aaron Gholson" className="img-fluid about-image" />
        </div>
      </div>
      <div className="row" id='bottom-row'>
        <div className="col-12">
          <p className="about-text">Initially, my plan was to attend college and earn a degree in graphic design to kickstart my career. After taking a year off after high school, I enrolled at Colorado Mesa University. During my studies, I stumbled upon the game development side of YouTube and realized there was a huge community interested in creating games. This excited me because I realized I could create a YouTube channel to share my games with the world. Despite lacking confidence and struggling with content creation, I never gave up. To this day, I continue developing my skills as a content creator.</p>
          <p className="about-text">In 2019, my brand, LegendDairy Productions, was born. Unfortunately, during my second year in college, COVID-19 struck, forcing everyone into quarantine. Staying awake and attentive to college lectures from my bedroom was challenging, so I decided to leave college and pursue a programming bootcamp instead. I needed something hands-on to keep me engaged, and I didn’t want to spend another two years in school. That’s when I found Flatiron School. It was exactly what I was looking for, with an option to complete the course in three months.</p>
          <p className="about-text">Life had other plans, though. The day before my bootcamp interview, I discovered my girlfriend was pregnant. It was a surprise but a welcome one, even if it meant putting my bootcamp dreams on hold for a while. A few months after my son was born, I got the opportunity to attend Flatiron, and I took it. Three short months later, I earned my certificate in Software Engineering! The course went smoothly for me, thanks to my self-taught background and advanced programming skills.</p>
          <p className="about-text">Jumping into a career had to wait a bit longer. To provide a secure lifestyle for my new family, we moved out of the expensive state of Colorado and relocated to Texas about six months after completing the bootcamp. Now, my life revolves around my family and building my brand to become a successful indie game developer, content creator, and software engineer.</p>
          <p className="about-text">Looking back over the past 25 years, I've realized that the path to success is never a straight line. It's filled with unexpected twists and turns. My passion for creating drives me to keep pushing forward, eager to share my work with the world. Every challenge is a chance to learn, and every success fuels my growth as both a developer and a content creator. My journey is far from over, and I'm excited for what lies ahead.</p>
        </div>
      </div>
      <div className="quote-container">
        <blockquote className="quote-text">
          "Don't wait for the right opportunity: create it." - George Bernard Shaw
        </blockquote>
      </div>
    </div>
  );
}

export default About;