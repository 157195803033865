import React from 'react';
import './Portfolio.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FlatironLogo from './flatiron_logo.png';
import CMULogo from './cmu_logo.png';
import SHHSLogo from './shhs_logo.png';

function Portfolio() {
  return (
    <>
      <section id="git-hub" className="github-section">
        <div className="github-card-wrapper">
                <a href="https://github.com/adgholson" target="_blank" rel="noopener noreferrer" className="card-link">
                  <div className="card horizontal-card">
                    <div className="row no-gutters">
                      <div className="col-md-4">
                        <img src="https://avatars.githubusercontent.com/adgholson" className="card-img" alt="GitHub Profile" />
                      </div>
                      <div className="col-md-8">
                        <div className="git-card-body">
                          <h5 className="git-card-title">Check out my GitHub</h5>
                          <p className="card-text">All my projects and repositories at the click of a button!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
        </section>
        <section id="education" className="education-section">
        <div className="container">
          <h2 className="text-center">Education</h2>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <img src={FlatironLogo} alt="Flatiron School Logo" className="school-logo" />
                    </div>
                    <div className="col-md-10">
                      <h5 className="education-card-title">Flatiron School</h5>
                      <div className="education-details">
                        <p>Attained a certificate in Full Stack Web Development, specializing in Python with Flask and JavaScript, completing an intensive program within a three-month period.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <img src={CMULogo} alt="Colorado Mesa University Logo" className="school-logo" />
                    </div>
                    <div className="col-md-10">
                      <h5 className="education-card-title">Colorado Mesa University</h5>
                      <div className="education-details">
                        <p>Engaged in two years of study in 3D graphics and design. Unfortunately, due to the unprecedented circumstances of the COVID-19 pandemic, the program was regrettably interrupted before completion.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <img src={SHHSLogo} alt="Smoky Hill High School Logo" className="school-logo" />
                    </div>
                    <div className="col-md-10">
                      <h5 className="education-card-title">Smoky Hill High School</h5>
                      <div className="education-details">
                        <p>Attained a high school diploma over the course of four years, laying the foundation for further academic and personal growth.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="skills" className="skills-section">
        <div className="container">
          <h2 className="text-center">Skills</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Professional Skills</h5>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                      Leadership <span className="progress-percent">90%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                      Teamwork <span className="progress-percent">80%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                      Problem Solving <span className="progress-percent">85%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                      Communication <span className="progress-percent">75%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                      Time Management <span className="progress-percent">85%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                      Organization <span className="progress-percent">90%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                      Adaptability <span className="progress-percent">90%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '95%'}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                      Professionalism <span className="progress-percent">95%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Technical Skills</h5>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '95%'}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                      React <span className="progress-percent">95%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                      Javascript <span className="progress-percent">85%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                      Python <span className="progress-percent">75%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                      CSS <span className="progress-percent">90%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                      HTML <span className="progress-percent">80%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                      C# <span className="progress-percent">85%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="890" aria-valuemin="0" aria-valuemax="100">
                      Blender <span className="progress-percent">90%</span>
                    </div>
                  </div>
                  <div className="progress mb-3">
                    <div className="progress-bar" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                      Photoshop <span className="progress-percent">85%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="work" className="work-section">
        <div className="container">
          <h2 className="text-center">Employment History</h2>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="work-card-title">Current Job</h5>
                  <div className="work-details">
                    <p>Position: Returns Material Handler</p>
                    <p>Company: O'Reilly Auto Parts</p>
                    <p>Location: Houston, Texas</p>
                    <p>Duration: December 2023 - Present</p>
                    <p>Description: Perform one or more warehouse duties such as the unloading, reboxing, or checking involved in the processing of all inbound returns. </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="work-card-title">Past Jobs</h5>
                  <div className="work-details">
                    <p>Position: Lead Delivery Driver</p>
                    <p>Company: Inyati LLC</p>
                    <p>Location: Denver, Colorado</p>
                    <p>Duration: September 2021 - April 2023</p>
                    <p>Description: Delivered Amazon orders using a company-owned van. Assisted in training new employees and occasionally managed the dispatch desk.</p>
                  </div>
                  <div className="work-details">
                    <p>Position: Assistant General Manager</p>
                    <p>Company: Sizzling Platter LLC</p>
                    <p>Location: Aurora, Colorado</p>
                    <p>Duration: June 2019 - September 2021</p>
                    <p>Description: Served as Second-in-Command at a successful Little Caesars Pizza franchise. Responsibilities included crew management, scheduling, truck ordering, and new employee training.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-me" className="contact-section">
        <div className="contact-card-wrapper">
                <a href="https://www.linkedin.com/in/adgholson/" target="_blank" rel="noopener noreferrer" className="card-link">
                  <div className="card horizontal-card">
                    <div className="row no-gutters">
                      <div className="col-md-4">
                        <img src="https://avatars.githubusercontent.com/adgholson" className="card-img" alt="LinkedIn Profile" />
                      </div>
                      <div className="col-md-8">
                        <div className="git-card-body">
                          <h5 className="git-card-title">Contact Me</h5>
                          <p className="card-text">Find me on LinkedIn or email me</p>
                          <p className="card-text">adgholson98@gmail.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
        </section>
    </>
  );
}

export default Portfolio;
