import React from 'react';
import './Brand.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LogoImage from './LDLogoLong.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faXTwitter, faInstagram, faReddit, faTiktok } from '@fortawesome/free-brands-svg-icons';

function Brand() {
  return (
    <div className="brand-container">
      <img src={LogoImage} alt="LegendDairy Logo" className="brand-image" />
      <div className="social-icons">
        <a href="https://www.youtube.com/channel/UCaYBxVkAgdgjb06GvErd3NQ" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a href="https://twitter.com/LegendDairyProd" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
        <a href="https://www.instagram.com/legenddairyprod/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.reddit.com/user/LegendDairyProd/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faReddit} />
        </a>
        <a href="https://www.tiktok.com/@legenddairyprod" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTiktok} />
        </a>
      </div>
      <div className="tip-container">
        <blockquote className="tip-text">
        Take a look at my skills in practice!
        </blockquote>
      </div>
    </div>
  );
}

export default Brand;